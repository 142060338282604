import React from "react";
import {
    dehydrate,
    DehydratedState,
    QueryClient,
    useQuery,
} from "@tanstack/react-query";
import { GetStaticProps, NextPage } from "next";

import { ESeoPage } from "@/utils/mockData/metaMocks";
import { getBrochureData, getNavBarData, getSeoInfo } from "@/utils/queries";
import Layout from "../components/common/Layout";

import ErrorPageBody from "../components/ErrorPage/ErrorPageBody";

const FourOhFour: NextPage = () => {
    const { data: metaTags } = useQuery({
        queryKey: ["metaTags", ESeoPage.FOUROHFOUR],
        queryFn: () => getSeoInfo(ESeoPage.FOUROHFOUR),
    });

    return (
        <Layout metaTags={metaTags!}>
            <ErrorPageBody
                errorCode="404"
                errorMessage="Sorry we couldn't find this page!"
            />
        </Layout>
    );
};

export const getStaticProps: GetStaticProps = async (): Promise<{
    props: {
        dehydratedState: DehydratedState;
    };
}> => {
    const queryClient = new QueryClient();

    await Promise.all([
        await queryClient.prefetchQuery(["navBar"], getNavBarData),
        await queryClient.prefetchQuery(["brochures"], getBrochureData),
        await queryClient.prefetchQuery(["metaTags", ESeoPage.FOUROHFOUR], () =>
            getSeoInfo(ESeoPage.FOUROHFOUR)
        ),
    ]);

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
        },
    };
};

export default FourOhFour;
